import * as React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Button } from 'components/styled/button'

import { SCREEN } from 'styles/screens'
import { unset } from 'lodash'

const FixedContainer = styled.div`
display: none;
 //display: flex;
 justify-content: space-between;
 align-items: center;
   // padding-inline: 10px;
    border: 2px solid rgba(236, 251, 112, 1);
   border-radius: 5px;
  max-width: 400px;
  max-height: 150px;
transform: translateY(80px);
   flex-direction: row;
   margin-top: 100px !important;

   >a{
   transform: translate(-10px, 0px);
   font-size: 15px !important;
   }
  
  .text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;

      .speech-bubble {
          line-height: 1.2;
    font-size: 14px;
    font-weight: 500;
    width: 148px;
    height: max-content;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding-inline: 10px;
    position: relative;
    background: rgb(236, 251, 112);
    border-radius: 0.4em;
    border: 2px solid rgb(236, 251, 112);
    transform: translate(-29px, -107px);

  > span {
  padding: 10px;
  width: max-content;
  }
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 50px solid transparent;
	border-top-color: rgba(236, 251, 112, 1);
	border-bottom: 0;
	border-right: 0;
	margin-left: -25px;
	margin-bottom: -50px;
}
 > h5{
    font-weight: 700;
    max-width: 100px;
  }

    }

  .albert-img{
    max-width: 220px;
    transform: translateY(-66px);
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: flex;
    
`

const CallButton = styled(Button)`
  font-size: 20px;
  padding: 16px !important;
  font-weight: 500 !important;
  white-space: nowrap; 
  transform: translateY(6px);
`

const AlbertSectionResponsive = ({text, value}) => (
    <FixedContainer style={{margin: 50}}>
  <div className='text' style={{transform: value == 'wall-of-love' ? "translateY(-20px)" : unset}}>

    <div className='speech-bubble'>
    <span>{text}</span>
  </div>
  
  <div className='albert-img'>
      <StaticImage
          src="../../images/albert 1 (2).png"
      />
    </div>
  </div>
  <a href='https://calendly.com/buildup-ab/30min?back=1&month=2024-10'>
    <CallButton>Book a call</CallButton>
    </a>
  </FixedContainer>
  )

export default AlbertSectionResponsive