import { getTranslation } from 'data/dictionary'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'
import { SCREEN } from 'styles/screens'
import { Lin } from './typography'


const DescriptionContainer = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1200px;
  > h5 {
    font-weight: 400;
    line-height: 1.7;
  }
  > button {
    margin-right: 12px;
    > svg {
      margin-left: 14px;
    }
  }

  .here {
    font-weight: 400;
    color: var(--primary);
    font-size: 22px;
  }

  small {
    max-width: 400px;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    > p:first-child {
      margin-top: 0;
    }
    small {
      max-width: 270px;
    }
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  margin-top: 50px;
  min-height: max-content;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    margin-top: 20px;
    margin-bottom: 0px;

    >h3 {
      width: max-content;
    }
  }
`

const NewFocusSection = () => {
    return (
        <Container>
            <Container><h3>
              {
                getTranslation('new-focus', '', false)
              }
            </h3>
   <StaticImage
        src="../../images/idea-svgrepo-com1.png"
    />

     <DescriptionContainer>
      <h5>
        {
          getTranslation('at-buildup-school', '', true)
        }
      </h5>

      <h5>
        {
          getTranslation("for-more-info", 'Read our detailed blog post ', true)
        }
        <a className='here' href='https://buildup.am/blog/problem-based-learning-powered-by-ai'>here.</a>
      </h5>
     </DescriptionContainer>
   </Container>
        </Container>
    )
}

export default NewFocusSection;